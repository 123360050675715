import {
  painting,
  demolition,
  dryrot,
  patiocovers,
  windowreplace,
  siding,
  framing,
  remodeling,
  flooring,
  drywall,
} from "../assets";

export const navLinks = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about" },
  { name: "Our Services", path: "/services" },
  { name: "Gallery", path: "/gallery" },
  { name: "Contact Us", path: "/contact" },
];

export const services = [
  {
    service_id: "1",
    image: dryrot,
    title: "Dryrot Repair",
    header: "Revitalize Your Home",
    body: "Our team of experts will meticulously repair any dry rot damage, ensuring your home remains structurally sound and aesthetically pleasing. We use top-quality materials to guarantee long-lasting results.",
    slogan: "Restoring Your Home, One Beam at a Time",
  },
  {
    service_id: "2",
    image: windowreplace,
    title: "Window Replacement",
    header: "A Clear View to Comfort",
    body: "We provide window replacement services that not only enhance the look of your home but also improve energy efficiency. Our wide range of styles and finishes ensures a perfect fit for your home.",
    slogan: "Windows That Reflect Your Style",
  },
  {
    service_id: "3",
    image: framing,
    title: "Framing",
    header: "Building Your Dreams",
    body: "Our skilled craftsmen provide top-notch framing services. We adhere to the highest standards of safety and precision.",
    slogan: "Crafting Structures, Framing Futures",
  },
  {
    service_id: "4",
    image: remodeling,
    title: "Remodeling",
    header: "Transform Your Space",
    body: "Our remodeling services are designed to bring your vision to life. Whether it's a kitchen makeover or a bathroom upgrade, we ensure every detail aligns with your expectations.",
    slogan: "Reimagine, Remodel, Rejoice",
  },
  {
    service_id: "5",
    image: drywall,
    title: "Drywall Repair",
    header: "Smooth Walls, Happy Homes",
    body: "From minor dents to large holes, our team can repair any drywall damage, leaving your walls looking brand new. We ensure a smooth finish and a seamless paint job.",
    slogan: "For Walls That Witness Life",
  },
  {
    service_id: "6",
    image: siding,
    title: "Siding",
    header: "Shield Your Home",
    body: "Our high-quality siding not only enhances your home's curb appeal but also provides a protective shield against the elements. Choose from a variety of styles and materials to match your home's aesthetic.",
    slogan: "Siding That Stands the Test of Time",
  },
  {
    service_id: "7",
    image: painting,
    title: "Painting",
    header: "Color Your World",
    body: "Our professional painting services can transform your space. We use high-quality paints and precise techniques to ensure a flawless finish, whether you're updating a single room or your entire home.",
    slogan: "Painting Perfection in Every Stroke",
  },
  {
    service_id: "8",
    image: patiocovers,
    title: "Patio Covers",
    header: "Enjoy the Outdoors, Rain or Shine",
    body: "Our patio covers allow you to enjoy your outdoor space regardless of the weather. Designed to blend seamlessly with your home, they provide shade and protection while enhancing your outdoor living experience.",
    slogan: "Covering Your Outdoor Comfort",
  },
  {
    service_id: "9",
    image: demolition,
    title: "Demolition and Fire Damage",
    header: "Clearing and Restoring After the Unthinkable",
    body: "Our comprehensive services cover both demolition and fire damage restoration. We handle everything from safe and efficient demolition for your site's next chapter to smoke and soot removal and structural repairs, helping you move forward after a fire.",
    slogan: "From Demolition to Restoration, We've Got You Covered",
  },
];

export const reviews = [
  {
    name: "William T.",
    review: "If I could give more than 5 stars I would. I recommend him to all. Larry does the greatest job from the numerous handy men I have hired in the past. With Larry I don't need to keep looking for someone to do a spectacular job. Larry is just that guy.",
  },
  {
    name: "Daniel Z.",
    review: "I had Larry do a lot of work for me recently, I'm proud to say I don't regret paying what I paid, he took care of all of the dry rot I had in my subfloor then replaced the sheet rock that was damaged as well as the baseboard on my home. He's capable of a lot and I highly recomment hiring him for your next project.",
  },
  {
    name: "Alex T.",
    review:
      "Larry is a professional, he is proficient with his work and can get things done in a timely manner. Will hire him again for future projects.",
  },
  {
    name: "Anatolii D.",
    review:
      "We're glad we went with Larry's team because they did an awesome job helping us out with some of our projects that we had, Larry does a great job communicating and they did an outstanding job replacing windows and painted our house!",
  },
  {
    name: "давид ч.",
    review: "Well done master, hands on, it’s a pleasure to deal with such a person",
  },
  {
    name: "Deanna Z.",
    review: "I got Larry recommended to me by a friend and I’m glad that I went with him to do some remodeling at our home, his prices are fair and they did a great job and finished everything within a week from start to finish. Larry and his team are professionals worth hiring!",
  },
];

export const IMAGES = [
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/dcsz3jx.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/yn8AA3H.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/WaResCc.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/ZiQi0z5.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/h8AgzPw.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/YNeIQ8l.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/tiBAbUP.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/MsvTBZ4.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/ckbnW9l.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/xzgA5cv.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/Tb4pcRV.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/EEs67cy.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/bftSN1x.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/xev3aAy.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/0EOYC7T.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/EpCPU0q.jpg",
  },

  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/HTqc6mx.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/ZqgiGW4.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/30YmpwI.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/0HVrjkq.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/FyAjeTE.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/tkOhx2f.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/I7JPOh4.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/VuByMOj.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/7fXM7Vq.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/bLj24ki.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/yn8AA3H.jpg",
  },
  {
    service_id: "1",
    service: "Dryrot Repair",
    url: "https://i.imgur.com/6MdRfFK.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/LeMnwbt.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/L1por55.jpg",
    role: "Maintenance Technician",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/GlcPeg4.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/DKsZ4m2.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/OJIxZbr.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/FoCzIpV.jpg",
    role: "Plumbing Specialist",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/zODQRLP.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/gKCz1Y2.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/bKbPGZD.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/LNXUoz1.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/TfuCDxM.jpg",
  },
  {
    service_id: "2",
    service: "Window Replacement",
    url: "https://i.imgur.com/W9fmlUv.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/89cl8KZ.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/qjBudkm.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/JCAwbx3.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/cq3v7FM.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/06jt1dk.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/qweZs53.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/Ltjksud.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/YWRSNWQ.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/h7ef2Gr.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/QiOC0Cr.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/eeQ72T4.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/5gdyL3o.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/ddpCjEd.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/R6UF0cq.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/Thc2lgB.jpg",
  },
  {
    service_id: "3",
    service: "Framing",
    url: "https://i.imgur.com/6RumDpn.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/31oLB2b.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/TV5FWkv.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/unaj5bG.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/dkSvELr.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/vpL9MpP.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/RotyDo1.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/saTYHL2.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/tkmsBjw.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/SVqRVrX.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/kgGftje.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/gFeAaor.jpg",
  },

  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/kOqTIX1.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/FOatmfN.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/fdiAhlF.jpg",
  },

  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/Md9JJMu.jpg",
  },
  {
    service_id: "4",
    service: "Remodel",
    url: "https://i.imgur.com/qhj4dhB.jpg",
  },
  {
    service_id: "5",
    service: "Drywall",
    url: "https://i.imgur.com/dD0PnU9.jpg",
  },
  {
    service_id: "5",
    service: "Drywall",
    url: "https://i.imgur.com/lYV8JDa.jpg",
  },
  {
    service_id: "5",
    service: "Drywall",
    url: "https://i.imgur.com/dR4LYMD.jpg",
  },
  {
    service_id: "5",
    service: "Drywall",
    url: "https://i.imgur.com/9tp2v32.jpg",
  },
  {
    service_id: "6",
    service: "Siding",
    url: "https://i.imgur.com/7H61XIm.jpg",
  },
  {
    service_id: "6",
    service: "Siding",
    url: "https://i.imgur.com/55hnoUB.jpg",
  },
  {
    service_id: "6",
    service: "Siding",
    url: "https://i.imgur.com/UY1EASP.jpg",
  },
  {
    service_id: "6",
    service: "Siding",
    url: "https://i.imgur.com/O1IJXSv.jpg",
  },
  {
    service_id: "8",
    service: "Patio Covers",
    url: "https://i.imgur.com/k5M60FE.jpg",
  },
  {
    service_id: "8",
    service: "Patio Covers",
    url: "https://i.imgur.com/PmY3AvA.jpg",
  },
  {
    service_id: "8",
    service: "Patio Covers",
    url: "https://i.imgur.com/Fs6kIKK.jpg",
  },
  {
    service_id: "8",
    service: "Patio Covers",
    url: "https://i.imgur.com/dka55Zm.jpg",
  },
  {
    service_id: "9",
    service: "Demolition & Fire Damage",
    url: "https://i.imgur.com/yO6ee74.jpg",
  },
  {
    service_id: "9",
    service: "Demolition & Fire Damage",
    url: "https://i.imgur.com/DH1xjtw.jpg",
  },
  {
    service_id: "9",
    service: "Demolition & Fire Damage",
    url: "https://i.imgur.com/xz1SZXU.jpg",
  },
  {
    service_id: "9",
    service: "Demolition & Fire Damage",
    url: "https://i.imgur.com/cfUp5mB.jpg",
  },
];
