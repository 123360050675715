import React from "react";
import GalleryHero from "../components/Heros/GalleryHero";
import ServiceGallery from "../components/ServiceGallery";
import ServiceSpecifics from "../components/ServiceSpecifics";

const GalleryID = () => {
  return (
    <div>
      <GalleryHero />
      <ServiceGallery />
    </div>
  );
};

export default GalleryID;
