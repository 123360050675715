import React from "react";
import AboutHero from "../components/Heros/AboutHero";
import AboutPage from "../components/AboutPage";

const About = () => {
  return (
    <div>
      <AboutHero />
      <AboutPage />
    </div>
  );
};
export default About;
